import { InitiateAuthResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import React from 'react';
// @ts-ignore
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _intersectionWith from 'lodash/intersectionWith';
import _find from 'lodash/find';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const { NEW_CLOUDFRONT_URL, PROD_EVL_CLIENT_ID_FOR_S3 } = publicRuntimeConfig;
// @ts-ignore
import validator from 'validator';

import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import jwtDecode from 'jwt-decode';
import TopBar from '../../header/Header';
import { useStyles } from '.';
import {
  checkConfirmedStop,
  registerUser,
  checkConfirmedStart,
  updateUserAttrs,
  confirmRegistration,
  resendConfirmationCode,
  authenticateUser,
  usernameAvailable,
  refreshTokens,
  emailAvailable,
} from '../../../api/user';
import {
  TitlePanel,
  ButtonBar,
  CustomAutoComplete,
  ValTextField,
  PasswordValidationCheckList,
  validatePassword,
  VerificationToken,
} from '../../../components';
import Loader from '../../../components/loader';
import {
  Attribute,
  CountryCodeNew,
  Status,
  UpdateUserAttrsConfig,
  RegisterUserConfig,
  AuthenticateUserConfig,
} from '../../../types';
import {
  usePlacePredictions,
  getCountryCode,
  updateLocalTokens,
  onEnterFocusNext,
  getRedirectUrl,
} from '../../../utils';
import Router from 'next/router';
import {
  UserDetailsValidations,
  ClashUsernameValidations,
  BirthdayValidations,
  CityValidations,
} from '../../../validations';
import { useFormatMessage } from 'evl-ui-lib';
import { monthOptions } from '../../../constants';
import { EvlBox, EvlContainer, EvlGrid, EvlLink, EvlTypography, EvlProgressBar, EvlSnackbar } from 'evl-ui-components';

interface StartDetailsState {
  username: string;
  password: string;
  email: string;
  wantsMarketing: boolean;
}

interface BirthdayDetailsState {
  birthday: string;
}

interface CityDetailsState {
  city: string;
}

interface RegisterPanelProps {
  returnHomeClick(): void;
  redirectPage: string;
  required?: string;
}

interface Claims {
  exp: any;
}

const RegisterPanel = ({ redirectPage, required }: RegisterPanelProps) => {
  const classes = useStyles();
  const translate = useFormatMessage();

  const generateYearOptions = () => {
    const years = [];
    let i = 1910;
    const currentYear = new Date().getFullYear();

    while (i <= currentYear) {
      years.unshift(i.toString());
      i++;
    }

    return years;
  };

  const generateDayOptions = () => {
    const days = [];
    let i = 1;

    while (i <= 31) {
      // 0 added to days with 1 digit as Cognito is only expecting dates with exactly 10 characters
      if (i < 10) {
        days.push('0' + i.toString());
      } else {
        days.push(i.toString());
      }
      i++;
    }

    return days;
  };

  const initialValues: StartDetailsState = {
    username: '',
    password: '',
    email: '',
    wantsMarketing: false,
  };

  const initialBirthdayValues: BirthdayDetailsState = {
    birthday: 'null/null/null',
  };

  const initialCityValues: CityDetailsState = {
    city: '',
  };

  const [values, setValues] = React.useState<StartDetailsState>(initialValues);
  const [birthdayValues, setBirthdayValues] = React.useState<BirthdayDetailsState>(initialBirthdayValues);
  const [cityValues, setCityValues] = React.useState<CityDetailsState>(initialCityValues);
  const [uniqueUsername, setUniqueUsername] = React.useState<string>(uuidv4());
  const [clashNewUserName, setclashNewUserName] = React.useState<string>('');
  const [failureMessage, setFailureMessage] = React.useState<string>('');
  const [fetchUserDetailsStatus, setFetchUserDetailsStatus] = React.useState<Status>(Status.blank);
  const [fetchRegisterStatus, setFetchRegisterStatus] = React.useState<Status>(Status.blank);
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [codeError, setCodeError] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const [submitReadableUsernameStatus, setSubmitReadableUsernameStatus] = React.useState<Status>(Status.blank);

  const [submitCodeStatus, setSubmitCodeStatus] = React.useState<Status>(Status.blank);

  const initialCodeValues: string[] = ['', '', '', '', '', ''];

  const [codeValues, setCodeValues] = React.useState<String[]>(initialCodeValues);

  const [resendCodeStatus, setResendCodeStatus] = React.useState<Status>(Status.blank);

  const [fetchLoginStatus, setFetchLoginStatus] = React.useState<Status>(Status.blank);

  const [fetchRefreshedTokens, setFetchRefreshedTokens] = React.useState<Status>(Status.blank);

  const [submitBirthdayStatus, setSubmitBirthdayStatus] = React.useState<Status>(Status.blank);

  const [submitCityStatus, setSubmitCityStatus] = React.useState<Status>(Status.blank);

  const initialVisiblePanel: string = 'userDetailsPanel';

  const [visiblePanel, setVisiblePanel] = React.useState<String>(initialVisiblePanel);

  const [cityInput, setCityInput] = React.useState<any>(undefined);

  const cityOptions = usePlacePredictions(cityInput || cityValues.city, {
    types: ['(cities)'],
  });

  const yearOptions = generateYearOptions();
  const dayOptions = generateDayOptions();

  const [selectedDay, setSelectedDay] = React.useState<any>('');
  const [selectedMonth, setSelectedMonth] = React.useState<any>('');
  const [selectedYear, setSelectedYear] = React.useState<any>('');

  const [dayInput, setDayInput] = React.useState<any>('');
  const [monthInput, setMonthInput] = React.useState<any>('');
  const [yearInput, setYearInput] = React.useState<any>('');

  const styleProps = {
    background: '#FFFFFF',
    marginLeft: '8px',
    width: 'calc(100% - 16px)',
  };

  React.useEffect(() => {
    if (fetchUserDetailsStatus !== Status.blank) return;
    window.scrollTo(0, 0);
  }, [fetchUserDetailsStatus]);

  React.useEffect(() => {
    if (required === 'birthdate') {
      setVisiblePanel('birthdayPanel');
      setCurrentStep(2);
      setFailureMessage(translate('registration.skipped.error'));
      setOpen(true);
    }
    if (required === 'location') {
      setVisiblePanel('cityPanel');
      setCurrentStep(3);
      setFailureMessage(translate('registration.skipped.error'));
      setOpen(true);
    }
  }, [required]);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Concatenates the inputs from the the 3 input boxes to post dob in cognito format
    if (selectedDay !== undefined && selectedMonth !== undefined && selectedYear !== undefined) {
      let dateOfBirth = '';
      dateOfBirth = selectedDay + '/' + selectedMonth + '/' + selectedYear;

      setBirthdayValues({ ...birthdayValues, birthday: dateOfBirth });
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  React.useEffect(() => {
    const dateOfBirth = birthdayValues.birthday.split('/');
    const day = dayOptions.find((day: any) => day === dateOfBirth[0]);
    setSelectedDay(day);
    setDayInput(day);
    let monthObject = monthOptions.find((month: any) => month.value === dateOfBirth[1]);
    monthObject && setSelectedMonth(monthObject.value);
    monthObject && setMonthInput(monthObject.label);
    const year = yearOptions.find((year: any) => year === dateOfBirth[2]);
    setSelectedYear(year);
    setYearInput(year);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const { password, email, wantsMarketing } = values;
      if (fetchRegisterStatus !== Status.inProgress) return;
      const newUsername = uuidv4();
      setUniqueUsername(newUsername);
      const userAttributes: Attribute[] = [
        {
          Name: 'email',
          Value: email,
        },
        {
          Name: 'custom:show_as_collector',
          Value: 'ON',
        },
        {
          Name: 'custom:has_accepted_terms',
          Value: `${Date.now().toString()}`,
        },
        {
          Name: 'custom:has_accepted_min_age',
          Value: `${Date.now().toString()}`,
        },
        {
          Name: 'custom:wants_marketing',
          Value: `${wantsMarketing}`,
        },
        {
          Name: 'custom:has_profile_picture',
          Value: 'false',
        },
      ];
      try {
        setFailureMessage('');
        const registerUserConfig: RegisterUserConfig = {
          username: newUsername,
          password,
          userAttributes,
        };

        await registerUser(registerUserConfig);
        checkConfirmedStart({ username: newUsername });
        setFetchRegisterStatus(Status.blank);
        setVisiblePanel('codePanel');
        return;
      } catch {
        setFetchRegisterStatus(Status.failure);
        return;
      }
    };
    fetchData();
  }, [fetchRegisterStatus]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (submitReadableUsernameStatus !== Status.inProgress) return;
      const userAttributes: Attribute[] = [
        {
          Name: 'preferred_username',
          Value: values.username.toUpperCase(),
        },
      ];
      try {
        setFailureMessage('');
        const updateUserAttrsConfig: UpdateUserAttrsConfig = {
          userAttributes,
        };

        await updateUserAttrs(updateUserAttrsConfig);
        setSubmitReadableUsernameStatus(Status.success);
        setFetchRefreshedTokens(Status.inProgress);
        return;
      } catch (err) {
        if (err == false) {
          setVisiblePanel('usernameClashPanel');
          setSubmitReadableUsernameStatus(Status.failure);
          return;
        }
        return;
      }
    };
    fetchData();
  }, [submitReadableUsernameStatus]);

  React.useEffect(() => {
    const getRefreshedTokens = async () => {
      if (fetchRefreshedTokens !== Status.inProgress) return;
      try {
        const refreshResp = await refreshTokens();
        updateLocalTokens(refreshResp.AuthenticationResult);
        setCurrentStep(2);
        setVisiblePanel('birthdayPanel');
      } catch (err) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_exp');
        Router.push('/');
      }
    };
    getRefreshedTokens();
  }, [fetchRefreshedTokens]);

  React.useEffect(() => {
    const submitCode = async () => {
      if (submitCodeStatus !== Status.inProgress) return;
      const confirmationCode = codeValues.join('');
      if (confirmationCode.length !== codeValues.length) {
        setCodeError(true);
        setSubmitCodeStatus(Status.failure);
        return;
      }
      try {
        await checkDuplUsername(values.username);
        await confirmRegistration({
          username: uniqueUsername,
          code: confirmationCode,
          attrName: 'email',
        });
        setFetchLoginStatus(Status.inProgress);
        checkConfirmedStop({ username: uniqueUsername });
        setSubmitCodeStatus(Status.blank);
      } catch (err) {
        setFailureMessage(
          err.message === 'Invalid verification code provided, please try again.'
            ? translate('forgotPassword.error.code')
            : err.message === 'Attempt limit exceeded, please try after some time.'
            ? translate('forgotPassword.error.attempts')
            : translate('forgotPassword.error.numeric'),
        );
        setOpen(true);
        setSubmitCodeStatus(Status.failure);
      }
    };
    submitCode();
  }, [submitCodeStatus]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (resendCodeStatus === Status.inProgress) {
        try {
          await resendConfirmationCode({
            username: uniqueUsername,
          });
          setResendCodeStatus(Status.success);
        } catch {
          setResendCodeStatus(Status.failure);
        }
      }
    };
    fetchData();
  }, [resendCodeStatus]);

  React.useEffect(() => {
    const fetchLogin = async () => {
      if (fetchLoginStatus !== Status.inProgress) return;
      try {
        const authConfig: AuthenticateUserConfig = {
          username: uniqueUsername,
          password: values.password,
        };
        const result: InitiateAuthResponse = await authenticateUser(authConfig);
        if (result.AuthenticationResult) {
          const token = result.AuthenticationResult.AccessToken || '';
          const idToken = result.AuthenticationResult.IdToken || '';
          const refreshToken = result.AuthenticationResult.RefreshToken || '';
          let claims: Claims = jwtDecode(token || '');
          localStorage.setItem('access_token', token);
          localStorage.setItem('token', token);
          localStorage.setItem('id_token', idToken);
          localStorage.setItem('refresh_token', refreshToken);
          localStorage.setItem('token_exp', claims.exp);
        }
        setFetchLoginStatus(Status.success);
        setSubmitReadableUsernameStatus(Status.inProgress);
      } catch {
        setFetchLoginStatus(Status.failure);
      }
    };
    fetchLogin();
  }, [fetchLoginStatus]);

  const checkDuplUsername = async (username: string) => {
    try {
      await usernameAvailable(username.toUpperCase());
      return true;
    } catch {
      throw false;
    }
  };

  const handleUserDetailsNextClick = (values: any) => {
    setFetchUserDetailsStatus(Status.inProgress);
    setValues(values);
    setFetchRegisterStatus(Status.inProgress);
  };

  const handleBirthdayNextClick = async (values: any) => {
    setSubmitBirthdayStatus(Status.inProgress);
    const userAttributes: Attribute[] = [
      {
        Name: 'birthdate',
        Value: values.birthday,
      },
    ];
    try {
      const updateUserAttrsConfig: UpdateUserAttrsConfig = {
        userAttributes,
      };
      await updateUserAttrs(updateUserAttrsConfig);
      const refreshResp = await refreshTokens();
      updateLocalTokens(refreshResp.AuthenticationResult);
      setSubmitBirthdayStatus(Status.success);
      setVisiblePanel('cityPanel');
      setCurrentStep(3);
      return;
    } catch (err) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_exp');
      Router.push('/');
    }
  };

  const handleCityNextClick = async () => {
    setSubmitCityStatus(Status.inProgress);
    const userAttributes: Attribute[] = [
      {
        Name: 'custom:city',
        Value: cityValues.city,
      },
    ];
    try {
      const updateUserAttrsConfig: UpdateUserAttrsConfig = {
        userAttributes,
      };
      await updateUserAttrs(updateUserAttrsConfig);
      const refreshResp = await refreshTokens();
      updateLocalTokens(refreshResp.AuthenticationResult);
      const page = decodeURIComponent(redirectPage);
      const routerPage =
        page === 'undefined'
          ? '/'
          : getRedirectUrl({
              originalRedirectPage: page,
            });
      window.location.href = routerPage;
    } catch (err) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_exp');
      Router.push('/');
    }
  };

  const handleCodePanelNextClick = () => {
    setSubmitCodeStatus(Status.inProgress);
  };

  const loginClick = () => {
    Router.push('/login?panel=normalLoginPanel');
  };

  const handleClashNextClick = (newValues: any, formikBag: any) => {
    checkDuplUsername(newValues.username)
      .then(() => {
        setclashNewUserName(newValues.username);
        setValues({ ...values, username: newValues.username });
        setSubmitCodeStatus(Status.inProgress);
      })
      .catch(() => {
        formikBag.setFieldError('username', 'Username already exists');
      });
  };

  const onBack = () => {
    if (visiblePanel === 'codePanel') {
      setVisiblePanel('userDetailsPanel');
      setCodeValues(initialCodeValues);
      setCodeError(false);
    } else if (visiblePanel === 'cityPanel') {
      setVisiblePanel('birthdayPanel');
      setCurrentStep(2);
    } else {
      Router.back();
    }
  };

  const handleResendClick = () => {
    setResendCodeStatus(Status.inProgress);
  };

  const renderUsernameField = (error: boolean) => (
    <ValTextField
      id="textfield-register-username"
      name="username"
      label={translate('registration.field.label.username')}
      value={values.username}
      autoCapitalize="off"
      autoComplete="off"
      onKeyDown={onEnterFocusNext}
      error={error}
      validate={validateUsername}
    />
  );

  const renderPasswordField = (error: boolean) => (
    <ValTextField
      id="textfield-register-password"
      type="password"
      name="password"
      isPasswordField
      label={translate('registration.field.label.password')}
      value={values.password}
      autoComplete="off"
      onKeyDown={onEnterFocusNext}
      validate={(v: string) => validatePassword(translate, v)}
      formHelperTextComponent={(meta: any) => <PasswordValidationCheckList {...meta} />}
      error={error}
    />
  );

  interface CredentialAvailability {
    [key: string]: boolean;
  }

  const usernameValidations: CredentialAvailability = {};
  const emailValidations: CredentialAvailability = {};

  const validateEmail = (value: any) => {
    if (!validator.isEmail(value || '')) {
      return translate('email.valid.error');
    }
    if (emailValidations[value || ''] !== undefined) {
      return emailValidations[value || ''] ? '' : translate('verifyEmail.used');
    } else {
      return emailAvailable(value?.toUpperCase() || '')
        .then(() => {
          emailValidations[value || ''] = true;
          return '';
        })
        .catch(() => {
          emailValidations[value || ''] = false;
          return translate('verifyEmail.used');
        });
    }
  };

  const validateUsername = (value: any) => {
    if (usernameValidations[value || ''] !== undefined) {
      return usernameValidations[value || ''] ? '' : translate('verifyUsername.used');
    } else {
      return usernameAvailable(value?.toUpperCase() || '')
        .then(() => {
          usernameValidations[value || ''] = true;
          return '';
        })
        .catch(() => {
          usernameValidations[value || ''] = false;
          return translate('verifyUsername.used');
        });
    }
  };

  const renderEmailField = (error: boolean) => (
    <ValTextField
      id="textfield-register-email"
      type="email"
      name="email"
      label={translate('registration.field.label.email')}
      value={values.email}
      autoComplete="off"
      onKeyDown={onEnterFocusNext}
      error={error}
      validate={validateEmail}
    />
  );

  const renderDateOfBirthField = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    errorMessage: string | undefined,
    error: boolean,
  ) => (
    <div className={classes.birthdayField}>
      <EvlTypography variant="body2" className={classes.dobLabel}>
        {translate('field.placeholder.dob')}
      </EvlTypography>
      <div className={classes.birthdayInputFields}>
        <div className={classes.birthdayDateInput}>
          <CustomAutoComplete
            id="textfield-register-birthday-day"
            value={selectedDay}
            noStartAdornment
            onChange={(_event: React.ChangeEvent<{}>, newValue: string | null) => {
              setFieldValue('birthday', newValue + '/' + selectedMonth + '/' + selectedYear);
              setSelectedDay(newValue);
            }}
            inputValue={dayInput}
            onInputChange={(_event: React.ChangeEvent<{}>, newInputValue: string) => {
              setDayInput(newInputValue);
            }}
            options={dayOptions}
            name="day"
            autoCapitalize="none"
            label={translate('field.placeholder.day')}
            onKeyDown={onEnterFocusNext}
            error={error}
          />
        </div>
        <div className={classes.birthdayMonthInput}>
          <CustomAutoComplete
            id="textfield-register-birthday-month"
            noStartAdornment
            value={selectedMonth}
            onChange={(_event: React.ChangeEvent<{}>, newValue: string | null) => {
              setFieldValue('birthday', selectedDay + '/' + newValue + '/' + selectedYear);
              setSelectedMonth(newValue);
            }}
            inputValue={monthInput}
            onInputChange={(_event: React.ChangeEvent<{}>, newInputValue: string) => {
              setMonthInput(newInputValue);
            }}
            autoComplete="off"
            options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
            getOptionLabel={(option: string) => {
              let valueLabel = monthOptions.find((month: any) => month.value === option);
              if (valueLabel) {
                return valueLabel.label;
              } else {
                return '';
              }
            }}
            name="month"
            autoCapitalize="none"
            label={translate('field.placeholder.month')}
            onKeyDown={onEnterFocusNext}
            error={error}
          />
        </div>
        <div className={classes.birthdayYearInput}>
          <CustomAutoComplete
            id="textfield-register-birthday-year"
            noStartAdornment
            value={selectedYear}
            onChange={(_event: React.ChangeEvent<{}>, newValue: string | null) => {
              setFieldValue('birthday', selectedDay + '/' + selectedMonth + '/' + newValue);
              setSelectedYear(newValue);
            }}
            inputValue={yearInput}
            onInputChange={(_event: React.ChangeEvent<{}>, newInputValue: string) => {
              setYearInput(newInputValue);
            }}
            autoComplete="off"
            options={yearOptions}
            name="year"
            autoCapitalize="none"
            label={translate('field.placeholder.year')}
            onKeyDown={onEnterFocusNext}
            error={error}
          />
        </div>
      </div>
      {error && (
        <EvlTypography variant="body2" className={classes.errorText}>
          {errorMessage}
        </EvlTypography>
      )}
    </div>
  );

  const renderCityField = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    formikCityValue: string,
    error: boolean,
  ) => (
    <div className={classes.fieldSectionContainer}>
      <CustomAutoComplete
        onChange={(_event: React.ChangeEvent<{}>, newValue: string | null) => {
          setFieldValue('city', newValue);
          if (!newValue) return;
          const splitNewValue = newValue.split(', ').pop();
          if (!splitNewValue) return;
          const country: CountryCodeNew | null = getCountryCode(splitNewValue);
          country && setCityValues({ city: newValue });
        }}
        onClose={(_event: React.ChangeEvent<{}>, reason: string) => {
          if (reason !== 'select-option' && formikCityValue !== cityValues.city) {
            setFieldValue('city', '');
            setCityInput('');
          }
          if (reason === 'select-option' && cityValues.city !== '') {
            setFieldValue('city', cityValues.city);
          }
        }}
        inputValue={cityInput || ''}
        onInputChange={(_event: React.ChangeEvent<{}>, newInputValue: string) => {
          if (formikCityValue !== cityValues.city || !formikCityValue) {
            setCityInput(newInputValue);
          }
        }}
        options={cityOptions}
        autoComplete="search"
        type="search"
        fullWidth
        id="textfield-register-city"
        name="city"
        label={translate('registration.field.label.city')}
        hideLabel
        autoCapitalize="none"
        onKeyDown={onEnterFocusNext}
        error={error}
      />
    </div>
  );

  const renderCodePanel = () => (
    <React.Fragment>
      <EvlBox pt={2.8} className={classes.authContainer}>
        <EvlContainer maxWidth="sm">
          <EvlGrid container justify="center" alignItems="center" spacing={3}>
            <EvlGrid item xs={12}>
              <EvlBox textAlign="center">
                <EvlTypography color="textPrimary" variant="h5" className={classes.header}>
                  {translate('registration.verify.title')}
                </EvlTypography>
                <EvlTypography
                  color="textSecondary"
                  variant="body2"
                  className={classes.subtext}
                  dangerouslySetInnerHTML={{
                    __html: translate('registration.verify.subtitle', { email: values.email }),
                  }}
                />
              </EvlBox>
            </EvlGrid>
            <EvlGrid item xs={12}>
              {submitCodeStatus !== Status.inProgress &&
              fetchLoginStatus !== Status.inProgress &&
              submitReadableUsernameStatus !== Status.inProgress &&
              fetchRefreshedTokens !== Status.inProgress ? (
                <>
                  <VerificationToken
                    hideCaption
                    hideText
                    codeValues={codeValues}
                    setCodeValues={setCodeValues}
                    inputType="numbers"
                    error={codeError}
                  />
                  <EvlGrid item xs={12} className={classes.forgotWrapper}>
                    <EvlTypography variant="body2" align="center" style={{ fontSize: '13.7px' }}>
                      {`${translate('forgotPassword.text.codeNotRecieved')}  `}
                      <EvlLink onClick={handleResendClick} className={classes.resendText}>
                        {translate('forgotPassword.link.resendCode')}
                      </EvlLink>
                    </EvlTypography>
                  </EvlGrid>
                </>
              ) : (
                <div className={classes.codeLoaderWrapper}>
                  <Loader />
                </div>
              )}
              <ButtonBar
                buttonList={[
                  {
                    buttonId: 'register-code-panel-next-button',
                    buttonType: 'submit' as 'submit',
                    buttonStyle: 'primary',
                    buttonLabel: translate('button.next'),
                    handleButtonClick: handleCodePanelNextClick,
                    buttonInProgress: submitCodeStatus === Status.inProgress,
                  },
                ]}
              />
              <EvlTypography
                color="textSecondary"
                variant="body2"
                className={classes.policies}
                dangerouslySetInnerHTML={{
                  __html: translate('register.codePanel.policies', {
                    privacy: `${NEW_CLOUDFRONT_URL}/${PROD_EVL_CLIENT_ID_FOR_S3}/files/PrivacyPolicy_EN.pdf`,
                    terms: `${NEW_CLOUDFRONT_URL}/${PROD_EVL_CLIENT_ID_FOR_S3}/files/TermsAndConditions_EN.pdf`,
                  }),
                }}
              />
            </EvlGrid>
          </EvlGrid>
        </EvlContainer>
      </EvlBox>
    </React.Fragment>
  );

  const renderUsernameClashPanel = () => (
    <React.Fragment>
      <TitlePanel
        title={translate('register.usernameClashPanel.title')}
        description={translate('register.usernameClashPanel.description')}
      />
      <Formik
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={ClashUsernameValidations(translate)}
        onSubmit={handleClashNextClick}
        initialValues={{ clashNewUserName }}
      >
        {({ isValid }) => (
          <Form>
            <ValTextField
              id="textfield-clash-username"
              name="username"
              label={translate('field.label.username')}
              value={clashNewUserName}
            />
            <ButtonBar
              buttonList={[
                {
                  buttonId: 'register-clash-next-button',
                  buttonType: 'submit' as 'submit',
                  buttonStyle: 'primary',
                  buttonLabel: translate('button.next'),
                  disableButton: !isValid,
                  isFormButton: true,
                },
              ]}
            />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );

  const renderStartPanel = () => (
    <React.Fragment>
      <EvlBox pt={2.8} className={classes.authContainer}>
        <EvlContainer maxWidth="sm">
          <EvlGrid container justify="center" alignItems="center" spacing={3}>
            <EvlGrid item xs={12}>
              <EvlBox textAlign="center">
                <EvlTypography color="textPrimary" variant="h5" className={classes.header}>
                  {translate('registration.start.title')}
                </EvlTypography>
                <EvlTypography color="textSecondary" variant="body2" className={classes.subtext}>
                  {translate('registration.start.subtitle')}
                </EvlTypography>
              </EvlBox>
            </EvlGrid>
            <EvlGrid item xs={12}>
              <Formik
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={false}
                validationSchema={UserDetailsValidations(translate)}
                onSubmit={handleUserDetailsNextClick}
                initialValues={{ ...initialValues, ...values }}
              >
                {({ errors, touched }) => {
                  return (
                    <Form autoComplete="off">
                      <EvlGrid container justify="center" alignItems="center" className={classes.fieldSpacing}>
                        {fetchRegisterStatus !== Status.inProgress ? (
                          <>
                            <EvlGrid item xs={12}>
                              {renderEmailField(errors.email !== undefined && touched.email === true)}
                            </EvlGrid>
                            <EvlGrid item xs={12}>
                              {renderPasswordField(errors.password !== undefined && touched.password === true)}
                            </EvlGrid>
                            <EvlGrid item xs={12}>
                              {renderUsernameField(errors.username !== undefined && touched.username === true)}
                            </EvlGrid>
                          </>
                        ) : (
                          <div className={classes.startLoaderWrapper}>
                            <Loader />
                          </div>
                        )}
                        <EvlGrid item xs={12}>
                          <ButtonBar
                            buttonList={[
                              {
                                buttonId: 'register-user-details-next-button',
                                buttonType: 'submit' as 'submit',
                                buttonStyle: 'primary',
                                buttonLabel: translate('button.next'),
                                buttonInProgress: fetchRegisterStatus === Status.inProgress,
                                disableButton: false,
                                isFormButton: true,
                              },
                            ]}
                          />
                        </EvlGrid>
                      </EvlGrid>
                    </Form>
                  );
                }}
              </Formik>
            </EvlGrid>
          </EvlGrid>
        </EvlContainer>
        <div className={classes.footer}>
          <EvlTypography color="textSecondary" variant="body2" className={classes.footerText}>
            {translate('login.existingAccount')}
          </EvlTypography>
          <EvlLink onClick={loginClick} className={classes.login}>
            {translate('button.login')}
          </EvlLink>
        </div>
      </EvlBox>
    </React.Fragment>
  );

  const renderBirthdayPanel = () => (
    <React.Fragment>
      <EvlBox pt={2.8} className={classes.authContainer}>
        <EvlContainer maxWidth="sm">
          <EvlGrid container justify="center" alignItems="center" spacing={3}>
            <EvlGrid item xs={12}>
              <EvlBox textAlign="center">
                <EvlTypography color="textPrimary" variant="h5" className={classes.header}>
                  {translate('registration.birthday.title')}
                </EvlTypography>
                <EvlTypography color="textSecondary" variant="body2" className={classes.subtext}>
                  {translate('registration.birthday.subtitle')}
                </EvlTypography>
              </EvlBox>
            </EvlGrid>
            <EvlGrid item xs={12}>
              <Formik
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={false}
                validationSchema={BirthdayValidations(translate)}
                onSubmit={handleBirthdayNextClick}
                initialValues={{ ...initialBirthdayValues, ...birthdayValues }}
              >
                {({ errors, touched, setFieldValue }) => {
                  return (
                    <Form autoComplete="off">
                      <EvlGrid container justify="center" alignItems="center" className={classes.fieldSpacing}>
                        {submitBirthdayStatus !== Status.inProgress ? (
                          <EvlGrid item xs={12}>
                            {renderDateOfBirthField(
                              setFieldValue,
                              errors.birthday,
                              errors.birthday !== undefined && touched.birthday === true,
                            )}
                          </EvlGrid>
                        ) : (
                          <div className={classes.birthdayLoaderWrapper}>
                            <Loader />
                          </div>
                        )}
                        <EvlGrid item xs={12}>
                          <ButtonBar
                            buttonList={[
                              {
                                buttonId: 'save-birthday-next-button',
                                buttonType: 'submit' as 'submit',
                                buttonStyle: 'primary',
                                buttonLabel: translate('button.next'),
                                buttonInProgress: submitBirthdayStatus === Status.inProgress,
                                disableButton: false,
                                isFormButton: true,
                              },
                            ]}
                          />
                        </EvlGrid>
                      </EvlGrid>
                    </Form>
                  );
                }}
              </Formik>
            </EvlGrid>
          </EvlGrid>
        </EvlContainer>
      </EvlBox>
    </React.Fragment>
  );

  const renderCityPanel = () => (
    <React.Fragment>
      <EvlBox pt={2.8} className={classes.authContainer}>
        <EvlContainer maxWidth="sm">
          <EvlGrid container justify="center" alignItems="center" spacing={3}>
            <EvlGrid item xs={12}>
              <EvlBox textAlign="center">
                <EvlTypography color="textPrimary" variant="h5" className={classes.header}>
                  {translate('registration.city.title')}
                </EvlTypography>
                <EvlTypography color="textSecondary" variant="body2" className={classes.subtext}>
                  {translate('registration.city.subtitle')}
                </EvlTypography>
              </EvlBox>
            </EvlGrid>
            <EvlGrid item xs={12}>
              <Formik
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={false}
                validationSchema={CityValidations(translate)}
                onSubmit={handleCityNextClick}
                initialValues={{ ...initialCityValues, ...cityValues }}
              >
                {({ errors, touched, setFieldValue, values: formikValues }) => {
                  return (
                    <Form autoComplete="off">
                      <EvlGrid container justify="center" alignItems="center" className={classes.fieldSpacing}>
                        {submitCityStatus !== Status.inProgress ? (
                          <EvlGrid item xs={12}>
                            {renderCityField(
                              setFieldValue,
                              formikValues.city,
                              errors.city !== undefined && touched.city === true,
                            )}
                          </EvlGrid>
                        ) : (
                          <div className={classes.cityLoaderWrapper}>
                            <Loader />
                          </div>
                        )}
                        <EvlGrid item xs={12}>
                          <ButtonBar
                            buttonList={[
                              {
                                buttonId: 'save-city-next-button',
                                buttonType: 'submit' as 'submit',
                                buttonStyle: 'primary',
                                buttonLabel: translate('button.createAccount'),
                                buttonInProgress: submitCityStatus === Status.inProgress,
                                disableButton: false,
                                isFormButton: true,
                              },
                            ]}
                          />
                        </EvlGrid>
                      </EvlGrid>
                    </Form>
                  );
                }}
              </Formik>
            </EvlGrid>
          </EvlGrid>
        </EvlContainer>
      </EvlBox>
    </React.Fragment>
  );

  return (
    <div className={classes.loginContainer}>
      <div className={classes.snackbar}>
        <EvlSnackbar open={open} success={false} message={failureMessage} handleClose={handleClose} duration={5000} />
      </div>
      <TopBar disableMenuDrawer onBack={onBack} title={translate('branding.signup')} styleProps={styleProps}>
        <div className={classes.progress}>
          <EvlProgressBar steps={3} currentStep={currentStep} />
        </div>
        {visiblePanel === 'userDetailsPanel' && renderStartPanel()}
        {visiblePanel === 'codePanel' && renderCodePanel()}
        {visiblePanel === 'birthdayPanel' && renderBirthdayPanel()}
        {visiblePanel === 'cityPanel' && renderCityPanel()}
        {visiblePanel === 'usernameClashPanel' && renderUsernameClashPanel()}
      </TopBar>
    </div>
  );
};

export default RegisterPanel;
