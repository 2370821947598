import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { authContainerSetup } from '../../../jss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authContainer: {
      ...authContainerSetup(theme).default,
    },
    introOptions1: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& button': {
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        width: '44%',
        minWidth: '120px',

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    introOptions2Header: {
      marginBottom: 15,
      padding: '0 16px',
      fontWeight: 1000,
      letterSpacing: 0,
    },
    logoContainer: {
      width: '80%',
      minWidth: 160,
      minHeight: 88,

      '& img': {
        width: '100%',
      },
    },
    discoverButton: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
    explainerSliderContainer: {
      // TO DO: update style block when adding the actual slider
      height: '100%',
      display: 'flex',
      padding: theme.spacing(8.75),
      width: '80%',
      minWidth: 360,
    },
    fullWidthDivider: {
      width: '100%',
      position: 'relative',
      maxWidth: theme.breakpoints.values.md,
      margin: '16px 0',
      borderBottom: `0.5px solid ${theme.palette.primary.main}`,
    },
    noTopPadding: {
      paddingTop: `0px !important`,
    },
    introBottomPadding: {
      paddingBottom: 80,
    },

    evlHeader: {
      maxWidth: theme.spacing(20),
      margin: `0 auto`,

      '& $img': {
        width: '100%',
      },
    },
    btnAuth: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.black,
      textTransform: 'none',
      borderRadius: theme.shape.borderRadius * 2,
      height: theme.spacing(3.6),
      fontWeight: 600,
      fontFamily: 'Lato',
      fontSize: '14px',
      marginBottom: '16px',
      marginTop: '4px',

      '& > span': {
        opacity: 0.87,
      },

      '&:hover': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #E7EBED',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    btnSignup: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: theme.shape.borderRadius * 2,
      height: theme.spacing(3.6),
      fontWeight: 600,
      fontFamily: 'Lato',
      fontSize: '14px',
      marginBottom: '16px',

      '&:hover': {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #062734',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    evlBrand: {
      width: '100%',
      margin: `${theme.spacing(0)} auto`,
      maxWidth: '100%',
    },
    panelHeader: {
      marginTop: '5px',
      marginBottom: '10px',
      fontFamily: 'Gotham Medium',
      fontWeight: 500,
    },
    panelText: {
      lineHeight: '16.8px',
    },
    ownership: {
      '& > div > img': {
        padding: '14px',
      },
    },
    container: {
      backgroundColor: '#062734',
      position: 'absolute',
      width: '100%',
      minHeight: '100%',
    },
    whiteContainer: {
      backgroundColor: '#fafafa',
      borderRadius: '20px',
      width: 'calc(100% - 16px)',
      padding: '0px 16px',
      marginBottom: '60px',
    },
    logo: {
      display: 'block',
      margin: '16px auto 13px auto',
    },
    indicators: {
      '& > div > div > ul': {
        paddingStart: '0 !important',
        marginAfter: '0 !important',
      },
    },
    center: {},
    '@media (min-height: 650px)': {
      center: {
        top: '50%',
        left: 0,
        right: 0,
        position: 'fixed',
        transform: 'translateY(-50%)',
      },
    },
  }),
);

export default useStyles;
