import * as React from 'react';
import { EvlBox, EvlGrid, EvlLink, EvlTypography } from 'evl-ui-components';
import useStyles from './FooterJSS';

type Props = {
  variant?: string;
  bottom?: number;
};

const Footer: React.FunctionComponent<Props> = ({ variant, bottom }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <EvlBox
        component="footer"
        textAlign="center"
        className={classes.evlFooterBox}
        style={bottom !== undefined ? { bottom: bottom } : {}}
      >
        <EvlGrid container justify="center" alignItems="center" spacing={0}>
          <EvlGrid item xs={12}>
            <EvlTypography variant="body2" style={{ color: variant && variant === 'light' ? '#FFFFFF' : '#383B41' }}>
              {'Powered by'}
            </EvlTypography>
          </EvlGrid>
          <EvlGrid item xs={12}>
            <EvlBox component="figure" style={{ margin: '0 auto' }}>
              <EvlLink target="_blank" href="http://everledger.io/">
                <img
                  className={classes.evlBrand}
                  src={variant && variant === 'light' ? '/static/images/EL_logo_light.svg' : '/static/images/logo.svg'}
                  alt=""
                />
              </EvlLink>
            </EvlBox>
          </EvlGrid>
        </EvlGrid>
      </EvlBox>
    </React.Fragment>
  );
};
export default Footer;
